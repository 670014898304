import { useReducer, useContext, createContext } from "react";

const StoreStateContext = createContext(null);
const StoreDispatchContext = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case "APP_STATE":
      return { ...state, ...action.payload };
    case "WORK_ITEMS":
      return { ...state, workItems: action.payload };
    case "WORKITEMS_TREE_TOGGLE":
      return { ...state, [action.payload.fullPath]: action.payload.toggle };
    case "TAGS":
      return { ...state, tags: action.payload };
    case "SHOW_MESSAGE":
      return {
        ...state,
        showMessage: true,
        message: action.payload.message,
        messageMode: action.payload.mode,
      };
    case "HIDE_MESSAGE":
      return { ...state, showMessage: false, message: "", messageMode: "" };
    case "DISPLAY_SETTING":
      return { ...state, type: "Auto", decimals: 6, zeroDisplay: "1.0E-12" };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    showMessage: false,
    message: "test",
    messageMode: "warning",
  });
  return (
    <StoreDispatchContext.Provider value={dispatch}>
      <StoreStateContext.Provider value={state}>
        {children}
      </StoreStateContext.Provider>
    </StoreDispatchContext.Provider>
  );
};

export const useStore = () => useContext(StoreStateContext);
export const useDispatchStore = () => useContext(StoreDispatchContext);
